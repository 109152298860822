<template>
  <header-modal ref="modalRef">
    <div class="menu header-modal__menu">
      <HeaderLinkNav
        v-for="link in links"
        :key="link.label"
        :label="link.label"
        :to="link.to"
        @click="close"
        class="link menu__link"
      />
    </div>
  </header-modal>
</template>

<script>
import { ref } from "vue";

import HeaderLinkNav from "@/components/Header/HeaderLinkNav.vue";
import HeaderModal from "@/components/Header/HeaderModal.vue";

export default {
  components: { HeaderLinkNav, HeaderModal },

  props: {
    links: Array,
  },

  expose: ["open", "close"],

  setup() {
    const modalRef = ref(null);

    const open = () => {
      if (modalRef.value === null) return;
      modalRef.value.open();
    };

    const close = () => {
      if (modalRef.value === null) return;
      modalRef.value.close();
    };

    return {
      modalRef,
      open,
      close,
    };
  },
};
</script>

<style scoped>
.header-modal__menu {
  display: flex;
  flex-flow: column;
  row-gap: 30px;
}
</style>
