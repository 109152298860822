<template>
  <div class="header-link-menu">
    <SvgIconsAccount36
      @click.stop.prevent="$emit('openMenu')"
      class="icon header-link-menu__icon"
    />
  </div>
</template>

<script>
import SvgIconsAccount36 from "@/components/Svg/Icons/SvgIconsAccount36.vue";

export default {
  components: { SvgIconsAccount36 },
  emits: ["openMenu"],
};
</script>

<style scoped>
.header-link-menu {
  align-self: center;

  display: flex;
  align-items: center;
}
.header-link-menu__icon {
  min-width: 24px;
}
.header-link-menu__icon:hover {
  cursor: pointer;
}
</style>
