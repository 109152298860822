<template>
  <container-modal ref="modalRef">
    <div class="header-modal">
      <slot />
    </div>
  </container-modal>
</template>

<script>
import { ref } from "vue";

import ContainerModal from "@/layouts/ContainerModal.vue";

export default {
  components: { ContainerModal },

  props: {
    links: Array,
  },

  expose: ["open", "close"],

  setup() {
    const modalRef = ref(null);

    const open = () => {
      if (modalRef.value === null) return;
      modalRef.value.open();
    };

    const close = () => {
      if (modalRef.value === null) return;
      modalRef.value.close();
    };

    return {
      modalRef,
      open,
      close,
    };
  },
};
</script>

<style scoped>
.header-modal {
  width: fit-content;
  height: fit-content;

  padding: 30px;

  position: absolute;
  top: var(--header-default--height);
  right: calc((100vw - var(--header-default--width)) / 2);

  background-color: #fff;
  border-radius: 10px;
}
</style>
