<template>
  <nav class="nav-header">
    <HeaderLinkNav
      v-for="link in links"
      :key="link.label"
      :label="link.label"
      :to="link.to"
      :type="link.type"
      :is-active="isActive(link.routeName)"
      class="link nav-header__link"
    />
  </nav>
</template>

<script>
import HeaderLinkNav from "@/components/Header/HeaderLinkNav.vue";
import { useRoute } from "vue-router";

export default {
  components: { HeaderLinkNav },

  props: {
    links: Array,
  },

  setup() {
    const route = useRoute();

    const isActive = (routeName) => {
      return route.name === routeName;
    };

    return {
      isActive,
    };
  },
};
</script>

<style scoped>
.nav-header {
  width: 100%;

  display: flex;
  flex-flow: row;
  column-gap: 30px;
}
</style>
