<template>
  <div class="container-right header__container-right">
    <HeaderLinkNavGroup :links="navLinks.desktopHeader" />
    <HeaderLinkWebsiteLanguage />
    <HeaderLinkMenu @open-menu="openModalMenu" />
    <HeaderModalMenu
      ref="modalMenuRef"
      :links="navLinks.desktopHeaderModalMenu"
    />
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import useNavLinks from "@/composables/useNavLinks";

import HeaderLinkNavGroup from "@/components/Header/HeaderLinkNavGroup.vue";
import HeaderLinkWebsiteLanguage from "@/components/Header/HeaderLinkWebsiteLanguage.vue";
import HeaderLinkMenu from "@/components/Header/HeaderLinkMenu.vue";
import HeaderModalMenu from "@/components/Header/HeaderModalMenu.vue";

export default {
  components: {
    HeaderLinkNavGroup,
    HeaderLinkWebsiteLanguage,
    HeaderLinkMenu,
    HeaderModalMenu,
  },

  setup() {
    const { navLinks } = useNavLinks();

    const modalMenuRef = ref(null);

    const openModalMenu = () => {
      if (modalMenuRef.value === null) {
        return;
      }
      modalMenuRef.value.open();
    };

    return {
      modalMenuRef,
      openModalMenu,
      navLinks,
    };
  },
};
</script>

<style scoped>
.header__container-right {
  display: flex;
  flex-flow: row;
  align-items: center;
  column-gap: 30px;

  position: relative;
}
</style>
